<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1000px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          Edit Order ({{ serverData.data[1].data[1].value }})
        </h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 pt-7 modal-body">
        <!--begin::Body-->
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
          show-arrows
        >
          <v-tab v-for="item in categories" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <perfect-scrollbar>
          <form id="editForm" class="form-body">
            <v-tabs-items v-model="tab" class="">
              <v-tab-item key="Status History" class="pt-2">
                <v-card color="basil" flat>
                  <pop-up-time-line
                    :item="getItemData('timeline')"
                  ></pop-up-time-line>
                </v-card>
              </v-tab-item>
              <v-tab-item key="Cosignee Details" class="pt-2">
                <v-card color="basil" flat>
                  <div class="">
                    <v-text-field
                      class
                      v-model="$v.formData.consignee_name.$model"
                      label="Consignee name"
                      clearable
                      outlined
                      :error-messages="consignee_nameErrors"
                      @input="$v.formData.consignee_name.$touch()"
                      @blur="$v.formData.consignee_name.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.consignee_phone.$model"
                      label="Consignee phone"
                      clearable
                      outlined
                      :error-messages="consignee_phoneErrors"
                      @input="$v.formData.consignee_phone.$touch()"
                      @blur="$v.formData.consignee_phone.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.consignee_mobile.$model"
                      label="Consignee mobile"
                      clearable
                      outlined
                      :error-messages="consignee_mobileErrors"
                      @input="$v.formData.consignee_mobile.$touch()"
                      @blur="$v.formData.consignee_mobile.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="formData.consignee_email"
                      label="Consignee email"
                      clearable
                      outlined
                    ></v-text-field>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item key="Order Details" class="pt-2">
                <v-card color="basil" flat>
                  <div class="">
                    <v-select
                      v-model="$v.formData.warehouse_id.$model"
                      label="Warehouse"
                      item-text="text"
                      item-value="index"
                      :items="serverData.warehouses"
                      clearable
                      outlined
                      :error-messages="warehouse_idErrors"
                      @input="$v.formData.warehouse_id.$touch()"
                      @blur="$v.formData.warehouse_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="">
                    <v-select
                      v-model="formData.carrier_id"
                      label="Carrier"
                      item-text="text"
                      item-value="index"
                      :items="serverData.carriers"
                      clearable
                      outlined
                    ></v-select>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.comment.$model"
                      label="Comment"
                      clearable
                      outlined
                      :error-messages="commentErrors"
                      @input="$v.formData.comment.$touch()"
                      @blur="$v.formData.comment.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.price.$model"
                      label="Price"
                      type="Number"
                      clearable
                      outlined
                      :error-messages="priceErrors"
                      @input="$v.formData.price.$touch()"
                      @blur="$v.formData.price.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-select
                      v-model="$v.formData.currency_id.$model"
                      label="Currency"
                      item-text="text"
                      item-value="index"
                      :items="serverData.currencies"
                      clearable
                      outlined
                      :error-messages="currency_idErrors"
                      @input="$v.formData.currency_id.$touch()"
                      @blur="$v.formData.currency_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="">
                    <v-select
                      v-model="$v.formData.payment_type_id.$model"
                      label="Payment type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.payment_types"
                      clearable
                      outlined
                      :error-messages="payment_type_idErrors"
                      @input="$v.formData.payment_type_id.$touch()"
                      @blur="$v.formData.payment_type_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="">
                    <v-select
                      v-model="$v.formData.order_type_id.$model"
                      label="Order type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.order_types"
                      clearable
                      outlined
                      :error-messages="order_type_idErrors"
                      @input="$v.formData.order_type_id.$touch()"
                      @blur="$v.formData.order_type_id.$touch()"
                    ></v-select>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.parcel_weight.$model"
                      label="Parcel weight"
                      clearable
                      outlined
                      :error-messages="parcel_weightErrors"
                      @input="$v.formData.parcel_weight.$touch()"
                      @blur="$v.formData.parcel_weight.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.parcel_volume.$model"
                      label="Parcel volume"
                      clearable
                      outlined
                      :error-messages="parcel_volumeErrors"
                      @input="$v.formData.parcel_volume.$touch()"
                      @blur="$v.formData.parcel_volume.$touch()"
                    ></v-text-field>
                  </div>
                  <div class="">
                    <v-text-field
                      v-model="$v.formData.number_of_boxes.$model"
                      label="number of boxes"
                      clearable
                      outlined
                      :error-messages="number_of_boxesErrors"
                      @input="$v.formData.number_of_boxes.$touch()"
                      @blur="$v.formData.number_of_boxes.$touch()"
                    ></v-text-field>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item key="Items" class="pt-2">
                <v-card color="basil" flat>
                  <v-col cols="12">
                    <sku-datatable
                      :setSku="setSkus"
                      :skus="formData.skus"
                      :skuItem="serverData.skus"
                      :clientId="serverData.order.client_id"
                    ></sku-datatable>
                  </v-col>
                </v-card>
              </v-tab-item>
              <v-tab-item key="Call History" class="pt-2">
                <v-card color="basil" flat>
                  <pop-up-table :item="getItemData('table')"></pop-up-table>
                </v-card>
              </v-tab-item>
              <v-tab-item key="Address" class="pt-2">
                <div class="">
                  <v-text-field
                    v-model="$v.formData.address.$model"
                    label="Address"
                    clearable
                    outlined
                    :error-messages="addressErrors"
                    @input="$v.formData.address.$touch()"
                    @blur="$v.formData.address.$touch()"
                  ></v-text-field>
                </div>
                <v-autocomplete
                  @change="onCountryChange"
                  v-model="$v.formData.country_id.$model"
                  label="Country"
                  item-text="text"
                  item-value="index"
                  :items="serverData.countries"
                  clearable
                  outlined
                  :error-messages="country_idErrors"
                  @input="$v.formData.country_id.$touch()"
                  @blur="$v.formData.country_id.$touch()"
                ></v-autocomplete>
                <v-autocomplete
                  @change="onStateChange"
                  :disabled="isStatesLoaded"
                  v-model="$v.formData.state_id.$model"
                  label="State"
                  item-text="title"
                  item-value="id"
                  :items="serverData.states"
                  clearable
                  outlined
                  :error-messages="state_idErrors"
                  @input="$v.formData.state_id.$touch()"
                  @blur="$v.formData.state_id.$touch()"
                ></v-autocomplete>
                <v-autocomplete
                  @change="onCityChange"
                  :disabled="isCitiesLoaded"
                  v-model="$v.formData.city_id.$model"
                  label="City"
                  item-text="title"
                  item-value="id"
                  :items="serverData.cities"
                  clearable
                  outlined
                  :error-messages="city_idErrors"
                  @input="$v.formData.city_id.$touch()"
                  @blur="$v.formData.city_id.$touch()"
                ></v-autocomplete>
                <v-autocomplete
                  :disabled="isAreasLoaded"
                  v-model="formData.area_id"
                  label="Area"
                  item-text="title"
                  item-value="id"
                  :items="serverData.areas"
                  clearable
                  outlined
                ></v-autocomplete>

                <div class="">
                  <v-text-field
                    v-model="formData.province"
                    label="Province"
                    clearable
                    outlined
                  ></v-text-field>
                </div>
                <div class="">
                  <v-text-field
                    v-model="formData.town"
                    label="town"
                    clearable
                    outlined
                  ></v-text-field>
                </div>

                <div class="">
                  <v-text-field
                    v-model="formData.zipcode"
                    label="Zipcode"
                    clearable
                    outlined
                  ></v-text-field>
                </div>
              </v-tab-item>
              <v-tab-item key="Current Status" class="pt-2">
                <v-card color="basil" flat>
                  <pop-up-text-group
                    :item="getItemData('text')"
                  ></pop-up-text-group>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </form>
        </perfect-scrollbar>
        <!--end::Body-->

        <div
          class="d-flex align-center justify-center justify-sm-space-between modal-action flex-wrap"
        >
          <v-btn
            width="120"
            @click="showPrevOrder(serverData.order.id)"
            class="order-4 order-sm-1"
            >previous</v-btn
          >

          <button
            type="reset"
            class="btn btn-light-danger px-5 py-3 order-3 order-sm-2"
            @click="showCancelModal"
          >
            Cancel Order
          </button>

          <button
            type="submit"
            class="btn btn-warning px-5 py-3 order-2 order-sm-3"
            @click="showHoldOnModal"
          >
            On Hold
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 order-1 order-sm-4"
            @click="submitEditForm"
          >
            Update information and Audit
          </button>
          <v-btn
            width="120"
            @click="showNextOrder(serverData.order.id)"
            class="order-5 order-sm-5"
            >next</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog2" scrollable max-width="300px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason == 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable max-width="300px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/orderManagement/pendingAuditOrders/skuContainer/SkuDatatable";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine";
import PopUpTable from "@/own/components/fulfillment/orders/popup/PopUpTable";
import PopUpTextGroup from "@/own/components/fulfillment/orders/popup/PopUpTextGroup";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: { PopUpTextGroup, PopUpTimeLine, SkuDatatable, PopUpTable },
  props: {
    itemForAction: {
      required: false,
      type: Number,
    },
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    extraData: {
      required: true,
      type: Object,
    },
    showNextOrder: {
      required: true,
      type: Function,
    },
    showPrevOrder: {
      required: true,
      type: Function,
    },
  },
  // ["XDOCK" => 9, "XSDOCK" => 17, "BOX" => 1, "RT" => 7, "RVP" => 4]
  // $UAECountryId = 229

  validations: {
    formData: {
      order_type_id: { required },
      currency_id: { required },
      payment_type_id: { required },
      price: {
        required: requiredIf(function () {
          return (
            this.formData.payment_type_id === 1 ||
            this.formData.country_id !== 229
          );
        }),
      },
      consignee_name: { required },
      consignee_phone: { required },
      consignee_mobile: { required },
      address: { required },
      city_id: { required },
      country_id: { required },
      state_id: { required },
      warehouse_id: { required },
      comment: {
        required: requiredIf(function () {
          return (
            this.formData.order_type_id === 9 ||
            this.formData.order_type_id === 1 ||
            this.formData.order_type_id === 7
          );
        }),
      },
      parcel_weight: {
        required: requiredIf(function () {
          return (
            this.formData.order_type_id === 9 ||
            this.formData.order_type_id === 1
          );
        }),
      },
      parcel_volume: {
        required: requiredIf(function () {
          return (
            this.formData.order_type_id === 9 ||
            this.formData.order_type_id === 1
          );
        }),
      },
      number_of_boxes: {
        required: requiredIf(function () {
          return (
            this.formData.order_type_id === 9 ||
            this.formData.order_type_id === 17
          );
        }),
      },
    },
  },
  data: () => ({
    fieldNames: {
      order_type_id: "Order type",
      currency_id: "Currency",
      payment_type_id: "Payment type",
      price: "Price",
      consignee_name: "Consignee name",
      consignee_phone: "Consignee phone",
      consignee_mobile: "Consignee mobile",
      consignee_email: "Consignee email",
      address: "Address",
      city_id: "City",
      country_id: "Country",
      state_id: "State",
      warehouse_id: "Warehouse",
      carrier_id: "Carrier",
      comment: "Comment",
      parcel_weight: "Parcel weight",
      parcel_volume: "Parcel volume",
      number_of_boxes: "Number of boxes",
    },
    formData: {
      id: null,
      consignee_name: null,
      consignee_phone: null,
      consignee_mobile: null,
      consignee_email: null,
      address: null,
      area_id: null,
      province: null,
      town: null,
      city_id: null,
      state_id: null,
      country_id: null,
      zipcode: null,
      comment: null,
      price: null,
      currency_id: null,
      payment_type_id: null,
      order_type_id: null,
      parcel_weight: null,
      parcel_volume: null,
      number_of_boxes: null,
      skus: [],
      warehouse_id: null,
      carrier_id: null,
    },

    dialog2: false,
    dialog3: false,
    dialog: false,
    menu2: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    serverData: null,
    tab: "Status History",
    categories: [
      "Status History",
      "Cosignee Details",
      "Order Details",
      "Items",
      "Call History",
      "Address",
      "Current Status",
    ],
    // ckeditorType: ClassicEditor,
  }),
  methods: {
    getItemData(type) {
      return this.infoData.filter((data) => data.type === type)[0];
    },
    actionUpdateAndAudit() {},
    showCancelModal() {
      this.dialog3 = !this.dialog3;
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoader(true);
        this.dialog3 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    showHoldOnModal() {
      this.dialog2 = !this.dialog2;
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoader(true);
        this.dialog2 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Hold On reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/order_management/pending_audit_orders/edit`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm()
            .then(() => {
              this.pageLoader(false);
            })
            .catch(() => {
              this.pageLoader(false);
            });
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    convertToFormData() {
      return this.formData;
    },
    errorMessageMaker() {
      let message = [];
      Object.keys(this.$v.formData.$params).forEach((fieldName) => {
        if ("required" in this.$v.formData[fieldName]) {
          !this.$v.formData[fieldName].required &&
            message.push(`The ${this.fieldNames[`${fieldName}`]} is required`);
        }
      });
      let a = "";
      message.forEach((mes) => {
        a = a + `<div>${mes}</div>`;
      });
      return `<div>${a}</div>`;
    },
    submitEditForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        Swal.fire({
          title: "Warning",
          html: this.errorMessageMaker(),
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      } else {
        // TODO: at least 1 sku should be added
        this.$v.$touch();
        if (this.formData.skus.length === 0) {
          Swal.fire({
            title: "Warning",
            html: `<div>At least one item should be added</div>`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        } else {
          this.pageLoader(true);
          let data = { ...this.formData };
          ApiService.post(`/order_management/pending_audit_orders/update`, data)
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: `Order has been updated`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              let id = this.serverData.order.id;
              this.showNextOrder(id);
            })
            .catch(() => {
              this.pageLoader(false);
            });
        }
      }
    },
    async resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.order };
        await Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.order).forEach((serverD) => {
            if (localD[0] === serverD[0] && localD[0] !== "image") {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
        if (this.serverData.order.country_id) {
          await this.onCountryChange(this.serverData.order.country_id, true);
        }
        if (this.serverData.order.state_id) {
          await this.onStateChange(this.serverData.order.state_id, true);
        }
        if (this.serverData.order.city_id) {
          await this.onCityChange(this.serverData.order.city_id);
        }
      } else {
        this.formData = {
          id: null,
          consignee_name: null,
          consignee_phone: null,
          consignee_mobile: null,
          consignee_email: null,
          address: null,
          area_id: null,
          province: null,
          town: null,
          city_id: null,
          state_id: null,
          country_id: null,
          zipcode: null,
          comment: null,
          price: null,
          currency_id: null,
          payment_type_id: null,
          order_type_id: null,
          parcel_weight: null,
          parcel_volume: null,
          number_of_boxes: null,
          skus: [],
          warehouse_id: null,
          carrier_id: null,
        };
      }

      this.tab = "Status History";
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    async onCountryChange(val, safe = false) {
      if (val) {
        this.pageLoader(true);
        let data = { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = false) {
      if (val) {
        this.pageLoader(true);
        let data = { state: val };
        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            this.dialog = false;
          });
      }
    },
    async onCityChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { city: val };
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
  },
  computed: {
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    infoData() {
      return this.serverData.data;
    },
    order_type_idErrors: function () {
      return this.handleFormValidation("order_type_id", this);
    },
    currency_idErrors: function () {
      return this.handleFormValidation("currency_id", this);
    },
    payment_type_idErrors: function () {
      return this.handleFormValidation("payment_type_id", this);
    },
    priceErrors: function () {
      return this.handleFormValidation("price", this);
    },
    consignee_nameErrors: function () {
      return this.handleFormValidation("consignee_name", this);
    },
    consignee_phoneErrors: function () {
      return this.handleFormValidation("consignee_phone", this);
    },
    consignee_mobileErrors: function () {
      return this.handleFormValidation("consignee_mobile", this);
    },
    addressErrors: function () {
      return this.handleFormValidation("address", this);
    },
    city_idErrors: function () {
      return this.handleFormValidation("city_id", this);
    },
    country_idErrors: function () {
      return this.handleFormValidation("country_id", this);
    },
    state_idErrors: function () {
      return this.handleFormValidation("state_id", this);
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    commentErrors: function () {
      return this.handleFormValidation("comment", this);
    },
    parcel_weightErrors: function () {
      return this.handleFormValidation("parcel_weight", this);
    },
    parcel_volumeErrors: function () {
      return this.handleFormValidation("parcel_volume", this);
    },
    number_of_boxesErrors: function () {
      return this.handleFormValidation("number_of_boxes", this);
    },
    // parcel_weight: null,
    //   parcel_volume: null,
    //   number_of_boxes: null,
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
}
.form-body {
  max-height: 64vh !important;
  height: 64vh !important;
  overflow-y: scroll;
}
</style>
